h1 {
    font-family: Nunito;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 72px */
}

h2 {
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 48px */
}

h3 {
    color: #FFF;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

h6 {
    color: #FFF;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    opacity: 0.4;
}

p {
    color: #FFF;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* opacity: 0.4; */
}

li {
    color: #FFF;
    font-family: "Nunito";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
}

label {
    color: #FF5B04;
    font-family: "Nunito";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }

    li {
        font-size: 14px;
    }
}