html {
    scroll-behavior: smooth;
}

.hero-section {
    background-image: url('../assets/image/hero-bg.png');
    background-position: 85% 10%;
    background-size: 70%;
    background-repeat: no-repeat;
}

.heading-description {
    color: #FFF;
    font-family: Nunito;
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    opacity: 0.6;
}

.card-white-text {
    color: #FFF;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.button-primary {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    background: radial-gradient(63.06% 100% at 50.59% 0.64%, rgba(255, 132, 30, 0.20) 0%, rgba(255, 132, 30, 0.00) 100%), radial-gradient(63.06% 100% at 50% 100%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #FF5B04;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-primary:hover {
    background: radial-gradient(63.06% 100% at 50.59% 0.64%, #FF841E 0%, rgba(255, 132, 30, 0.00) 100%), radial-gradient(63.06% 100% at 50% 100%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), var(--main-orange, #FF5B04);
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-primary:active {
    border: 2px solid var(--main-white, #FFF);
}

.button-primary-disabled {
    opacity: 0.3;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    background: radial-gradient(63.06% 100% at 50.59% 0.64%, rgba(255, 132, 30, 0.20) 0%, rgba(255, 132, 30, 0.00) 100%), radial-gradient(63.06% 100% at 50% 100%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #FF5B04;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-secondary {
    border-radius: 6px;
    border: 1px solid rgba(255, 91, 4, 0.20);
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.40) 0%, rgba(255, 102, 0, 0.15) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #090B0A;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-secondary:hover {
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.70) 0%, rgba(255, 102, 0, 0.26) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), var(--main-black, #090B0A);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-secondary:active {
    border: 2px solid var(--main-white, #FFF);
}

.button-secondary-disabled {
    border-radius: 6px;
    border: 1px solid rgba(255, 91, 4, 0.20);
    opacity: 0.3;
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.40) 0%, rgba(255, 102, 0, 0.15) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), var(--main-black, #090B0A);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-ghosts {
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.button-ghosts:active {
    border-bottom: 2px solid var(--main-white, #FFF);
}

.button-ghosts-disabled {
    opacity: 0.3;
}

.line-normal {
    line-height: normal;
}

.sm-button {
    display: flex;
    height: 34px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 62px;
    border: 1px solid rgba(255, 91, 4, 0.10);
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.10) 0%, rgba(255, 102, 0, 0.04) 100%);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
    color: #FF5B04;
    font-family: "Nunito";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.icon-button {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid rgba(255, 91, 4, 0.20);
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.20) 0%, rgba(255, 102, 0, 0.08) 100%), rgba(255, 255, 255, 0.02);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.crypto-background {
    flex-shrink: 0;
    border-radius: 24px;
    border: 1px solid rgba(124, 60, 0, 0.336);
    background: radial-gradient(71.88% 113.99% at 50% 178.33%, rgba(255, 102, 0, 0.10) 0%, rgba(255, 102, 0, 0.01) 100%);
}

.lock-filter {
    width: 364.744px;
    height: 364.744px;
    transform: rotate(-90deg);
    flex-shrink: 0;
    border-radius: 364.744px;
    opacity: 0.4;
    background: rgba(255, 91, 4, 0.30);
    filter: blur(153.9005126953125px);
}

.input {
    caret-color: #FF5B04;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(77, 77, 77, 0.10) 0%, rgba(77, 77, 77, 0.04) 100%), #090B0A;
}

.input:focus-within {
    border-color: #FF5B04 !important;
}

.input:hover {
    border-color: rgba(255, 255, 255, 0.20);
}

.input-disabled {
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    opacity: 0.3;
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(77, 77, 77, 0.10) 0%, rgba(77, 77, 77, 0.04) 100%), var(--main-black, #090B0A);
}

.bubble {
    fill: radial-gradient(100% 100% at 50% 100%, rgba(237, 92, 21, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.01) 100%), rgba(255, 255, 255, 0.01);
    stroke-width: 1px;
    stroke: rgba(255, 255, 255, 0.00);
    backdrop-filter: blur(9px);
    border-radius: 100%;
}

.works-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    opacity: 0.04;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 80.47%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.active-button {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FF5B04;
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.20) 0%, rgba(255, 102, 0, 0.08) 100%), rgba(255, 255, 255, 0.02);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.disable-button {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid rgba(255, 91, 4, 0.10);
    background: radial-gradient(63.06% 100% at 50% 100%, rgba(255, 102, 0, 0.10) 0%, rgba(255, 102, 0, 0.04) 100%);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset;
}

.progress-start-enable-line {
    width: 2px;
    height: 33vh;
    flex-shrink: 0;
    margin: auto;
    background: linear-gradient(180deg, rgba(237, 92, 21, 0.00) 0%, #ED5C15 100%);
}

.progress-start-disable-line {
    width: 2px;
    height: 33vh;
    flex-shrink: 0;
    margin: auto;
    background: linear-gradient(180deg, rgba(237, 92, 21, 0.00) 0%, rgba(237, 92, 21, 0.10) 100%);
}

.progress-enable-line {
    width: 1px;
    height: 63vh;
    flex-shrink: 0;
    background: #ED5C15;
    margin: auto;
}

.progress-disable-line {
    width: 1px;
    height: 63vh;
    flex-shrink: 0;
    background: rgba(237, 92, 21, 0.10);
    margin: auto;
}

.footer-grid {
    align-items: center;
}

.modal-container {
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.04) 100%), linear-gradient(0deg, rgba(9, 11, 10, 0.10) 0%, rgba(9, 11, 10, 0.10) 100%), rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(9px);
    top: 0;
    left: 0;
    z-index: 999;
}

.modal-background {
    border-radius: 24px;
    border: 1px solid var(--main-black, rgba(255, 102, 4, 0.30));
    background: var(--main-black, #090B0A);
}

.grecaptcha-badge {
    visibility: hidden !important;
}

@media screen and (max-width: 400px) {
    .footer-grid {
        align-items: start;
    }
}

@media screen and (max-width: 767px) {
    .active-button {
        width: 32px;
        height: 32px;
        padding: 6px;
    }

    .disable-button {
        width: 32px;
        height: 32px;
        padding: 6px;
    }

    .progress-start-enable-line {
        height: 60px;
    }

    .progress-start-disable-line {
        height: 60px;
    }

    .progress-enable-line {
        height: 120px;
    }

    .progress-disable-line {
        height: 120px;
    }

    .hero-section {
        background-position: 95% 120%;
        background-size: 130%;
    }
}